.App {
  display: flex;
  justify-content: center;
  padding: 2.5rem;
  overflow: visible;
}

.app-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95vw;
  max-width: 718px;
}

.app-transform {
  position: relative;
  transform-origin: 50% 0;
}

h1.main {
  margin-bottom: 4rem;
}

.table-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-around;
}

.table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

table.standard-coins,
.standard-coins thead,
.standard-coins td {
  border: 2px solid #000;
}

table.standard-coins {
  text-align: center;
  border-collapse: collapse;
  max-width: 95vw;
}

.standard-coins td {
  padding: 1rem;
}

td.std-toonie {
  height: 13.0rem;
  width: 13.0rem;
}

td.std-loonie {
  height: 12.0rem;
  width: 12.0rem;
}

td.std-quarter {
  height: 11.0rem;
  width: 11.0rem;
}

td.std-dime {
  height: 9.0rem;
  width: 9.0rem;
}

td.std-nickel {
  height: 10.0rem;
  width: 10.0rem;
}

td.std-penny {
  height: 9.5rem;
  width: 9.5rem;
}

img.std-toonie {
  height: 11rem;
  width: 11rem;
}

img.std-loonie {
  height: 10rem;
  width: 10rem;
}

img.std-quarter {
  height: 9rem;
  width: 9rem;
}

img.std-dime {
  height: 7rem;
  width: 7rem;
}

img.std-nickel {
  height: 8rem;
  width: 8rem;
}

img.std-penny {
  height: 7.5rem;
  width: 7.5rem;
}

.standard-coins thead td {
  height: auto !important;
  padding: 1.5rem 0 !important;
  min-width: auto !important;
  min-height: auto !important;
}

.standard-coins .side-td {
  width: auto;
  padding: 0 1.5rem;
  min-width: auto;
  min-height: auto;
}

.preview-block {
  display: flex;
  margin-top: 2.5rem;
  align-items: center;
  justify-content: space-around;
  width: 300px;
}

.img-preview {
  border-radius: 50%;

  width: 100px;
  height: 100px;
}

.circle-border,
.ReactCrop__crop-selection {
  border-radius: 50%;
}

.ReactCrop__image {
  max-height: none !important;
}

.ReactCrop {
  max-width: 500px !important;
}

.main-button-group {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 0;
  right: 0;
}

.main-button-group > * {
  margin-bottom: 1rem;
}

.date-links {
  margin-bottom: 2.5rem;
}

.table-links {
  margin-bottom: 4rem;
  display: flex;
  width: 60%;
  align-content: center;
  justify-content: space-between;
}

.table-links * {
  margin-bottom: 0;
}

button.link {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #BA9B60;
  cursor: pointer;
}

button.link:focus {
  outline: none;
}

button.active-link {
  color: #000;
  text-decoration: none;
  cursor: auto;
  font-weight: 500;
}

.hidden-input {
  height: 0.1px;
  width: 0.1px;
  color: transparent;
  background-color: transparent;
  position: absolute;
  border: none;
  top: 0;
  left: 0;
}

label.button {
  cursor: pointer;
}

label.button svg {
  color: #BA9B60;
  transition: 500ms;
  transform-origin: 50% 50%;
}

label.button:hover svg {
  transform: scale(1.05);
  color: #000;
}

.empty-cell-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.empty-cell-icons > * {
  margin: 1rem;
}

.hover-red {
  cursor: pointer;
  transition: 500ms;
  transform-origin: 50% 50%;
  color: #bb0000;
}

.hover-red:hover {
  transform: scale(1.05);
  color: #000;
}

.special-coin-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.special-coin-form-outer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.toggle-coin-form {
  position: absolute;
  right: 0;
  top: 0;
}

.special-coin-form {
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
}

.special-coin-wrapper {
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 2px solid #000;
  padding: 1px;
}

.special-coin-wrapper.hidden {
  display: none;
}

.special-coin-form.hidden {
  display: none;
}

.special-coin-edit.hidden {
  display: none;
}

.special-coin-box {
  text-align: center;
  border: 2px solid #000;
  border-radius: 2px;
  width: 17.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem 0;
  margin: 1px;
}

.special-coin-box img {
  border-radius: 50%;
  margin-bottom: 1.5rem;
}
.special-coin-box h6 {
  margin-bottom: 1.5rem;
}

.special-coin-box p {
  width: 12.0rem;
  max-width: 12.0rem;
  border-radius: 50%;
  margin: 0;
}

img.toonie {
  height: 11.0rem;
  width: 11.0rem;
}
img.loonie {
  height: 10.0rem;
  width: 10.0rem;
}
img.quarter {
  height: 9.0rem;
  width: 9.0rem;
}
img.dime {
  height: 7.0rem;
  width: 7.0rem;
}
img.nickel {
  height: 8.0rem;
  width: 8.0rem;
}
img.penny {
  height: 7.5rem;
  width: 7.5rem;
}
img.halfDollar {
  height: 9.0rem;
  width: 9.0rem;
}

.hide-special-form .table-links {
  margin-bottom: 2.5rem;
}

.hide-special-form .special-coin-wrapper {
  margin-top: 5rem;
}

.action-buttons {
  margin-bottom: 2.5rem;
  display: flex;
  width: 25rem;
  justify-content: space-between;
}

button.depressed {
  border: 2px inset #87682D;
  background: #BA9B60;
  border-radius: 2px;
}

button.danger {
  border: 2px outset #770000;
  background: #bb4444;
  border-radius: 2px;
}

@media (max-width: 550px) {
  .special-coin-box p {
    width: 16rem;
    max-width: 16rem;
  }
  .action-buttons {
    width: 30rem;
  }
}