@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500');

* {
  box-sizing: border-box;
  font-family: 'Ubuntu', sans-serif;
}

:root {
  font-size: 10px;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-size: 1.6rem;
}

h1 {
  font-size: 4.8rem;
}

h2 {
  font-size: 4.2rem;
}

h3 {
  font-size: 3.6rem;
}

h4 {
  font-size: 2.4rem;
}

h5 {
  font-size: 2.1rem;
}

h6 {
  font-size: 1.8rem;
}

p {
  font-size: 1.6rem;
}

@media (max-width: 800px) {
  :root {
    font-size: 9px;
  }
}

@media (max-width: 700px) {
  :root {
    font-size: 8px;
  }
}

@media (max-width: 650px) {
  :root {
    font-size: 7px;
  }
}

@media (max-width: 550px) {
  :root {
    font-size: 6px;
  }
  body {
    font-size: 2rem;
  }

  h1 {
    font-size: 5.2rem;
  }

  h2 {
    font-size: 4.6rem;
  }

  h3 {
    font-size: 4rem;
  }

  h4 {
    font-size: 2.8rem;
  }

  h5 {
    font-size: 2.5rem;
  }

  h6 {
    font-size: 2.2rem;
  }

  p {
    font-size: 2rem;
  }
}

@media (max-width: 500px) {
  :root {
    font-size: 5.5px;
  }
}

@media (max-width: 450px) {
  :root {
    font-size: 5px;
  }
}



h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  margin-bottom: 25px;
}

.mb5 {
  margin-bottom: 5px;
}